define("discourse/plugins/discourse-saved-searches/discourse/templates/preferences-saved-searches", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group category-saved-searches">
    <label class="control-label">{{i18n "saved_searches.title"}}</label>
  
    <p>{{i18n
        "saved_searches.description"
        count=this.siteSettings.max_saved_searches
      }}</p>
  
    {{#each this.savedSearches as |savedSearch|}}
      <div class="controls saved-searches-controls saved-search">
        <Input @type="text" @value={{savedSearch.query}} />
        <ComboBox
          @content={{this.savedSearchFrequencyOptions}}
          @valueProperty="value"
          @value={{savedSearch.frequency}}
          @onChange={{action (mut savedSearch.frequency)}}
        />
        <DButton
          class="remove-saved-search"
          @icon="trash-alt"
          @title="saved_searches.remove"
          @action={{action "removeSavedSearch" savedSearch}}
        />
      </div>
    {{else}}
      <div class="controls saved-searches-controls">
        {{i18n "saved_searches.none"}}
      </div>
    {{/each}}
  
    {{#if this.canAddSavedSearch}}
      <div class="controls saved-searches-controls">
        <DButton
          class="add-saved-search"
          @icon="plus"
          @label="saved_searches.add"
          @action={{action "addSavedSearch"}}
        />
      </div>
    {{/if}}
  </div>
  
  <SaveControls @model={{this}} @action={{action "save"}} @saved={{this.saved}} />
  */
  {
    "id": "eqWJAzjc",
    "block": "[[[10,0],[14,0,\"control-group category-saved-searches\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"saved_searches.title\"],null]],[13],[1,\"\\n\\n  \"],[10,2],[12],[1,[28,[35,2],[\"saved_searches.description\"],[[\"count\"],[[30,0,[\"siteSettings\",\"max_saved_searches\"]]]]]],[13],[1,\"\\n\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"savedSearches\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"controls saved-searches-controls saved-search\"],[12],[1,\"\\n      \"],[8,[39,6],null,[[\"@type\",\"@value\"],[\"text\",[30,1,[\"query\"]]]],null],[1,\"\\n      \"],[8,[39,7],null,[[\"@content\",\"@valueProperty\",\"@value\",\"@onChange\"],[[30,0,[\"savedSearchFrequencyOptions\"]],\"value\",[30,1,[\"frequency\"]],[28,[37,8],[[30,0],[28,[37,9],[[30,1,[\"frequency\"]]],null]],null]]],null],[1,\"\\n      \"],[8,[39,10],[[24,0,\"remove-saved-search\"]],[[\"@icon\",\"@title\",\"@action\"],[\"trash-alt\",\"saved_searches.remove\",[28,[37,8],[[30,0],\"removeSavedSearch\",[30,1]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],[[[1,\"    \"],[10,0],[14,0,\"controls saved-searches-controls\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"saved_searches.none\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"\\n\"],[41,[30,0,[\"canAddSavedSearch\"]],[[[1,\"    \"],[10,0],[14,0,\"controls saved-searches-controls\"],[12],[1,\"\\n      \"],[8,[39,10],[[24,0,\"add-saved-search\"]],[[\"@icon\",\"@label\",\"@action\"],[\"plus\",\"saved_searches.add\",[28,[37,8],[[30,0],\"addSavedSearch\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[8,[39,12],null,[[\"@model\",\"@action\",\"@saved\"],[[30,0],[28,[37,8],[[30,0],\"save\"],null],[30,0,[\"saved\"]]]],null]],[\"savedSearch\"],false,[\"div\",\"label\",\"i18n\",\"p\",\"each\",\"-track-array\",\"input\",\"combo-box\",\"action\",\"mut\",\"d-button\",\"if\",\"save-controls\"]]",
    "moduleName": "discourse/plugins/discourse-saved-searches/discourse/templates/preferences-saved-searches.hbs",
    "isStrictMode": false
  });
});